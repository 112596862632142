import stringUtil from "@/util/stringUtil";

const search = {
  /**
   * @name whitespaceAsWildcard
   *
   * Example:
   * Search query "foo bar" will give these
   * results
   *
   * foo bar      // hit
   * foo baz bar  // hit
   * bar foo      // no hit
   * baz foo      // no hit
   *
   * @param value
   * @param query
   */
  whitespaceAsWildcard(value, query) {
    query = stringUtil.escapeRegExp(query);
    const regExWildcard = ".+";
    query = query.replace(/\s+/g, regExWildcard);
    const regex = new RegExp(query, "gi");
    return regex.test(value);
  }
};

export default search;
